import React from "react"
import { Container, Row, Col, Button, Form, FormGroup, Input } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

export default class JoinThe extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selectedOption: null }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    alert(`Welcome ${this.state.languageSelect} ${this.state.emailAddress}!`)
  }

  render() {
    return (
      <Container fluid={true} className="py-4 jointheform">
        <Row>
          <Col className="jointhecol" xs="12" sm={{ size: 9, offset: 1 }}>
            <h3 className="text-center">
              Join our community of chicken parents.
              <br />
              <span style={{ color: `#e39f1e` }}>JOIN NOW (FREE):</span>
            </h3>

            <div style={{display:`flex`, justifyContent:`center`, alignItems:`center`, gap: `10px`}}>
            <StaticImage
            src="../images/book-cover.png"
            alt="Keeping Chickens"
            placeholder="blurred"
            layout="fixed"
            width={200}
            height={200}
          />
          <div style={{maxWidth: `350px`}}>
          <span style={{color:`#fff`, fontFamily: `Jost`, letterSpacing: `-1px`, display:`block`, marginBottom:`15px`}}>Join thousands of other chicken enthusiasts and we'll send you our popular <em>Keeping Chickens</em> guide (free!).</span>
          <Form
              inline
              action="https://www.getdrip.com/forms/509098596/submissions"
              method="post"
              data-drip-embedded-form="509098596"
            >
              <FormGroup className="mr-2">
                <Input
                  type="email"
                  name="fields[email]"
                  id="drip-email"
                  aria-label="Email"
                  placeholder="Enter your email..."
                />
              </FormGroup>
              
              <FormGroup>

              <DiscussButton
                data-drip-attribute="sign-up-button"
                className="joinsubmit"
              >
                Submit
              </DiscussButton>
              </FormGroup>
            </Form>
          </div>
            </div>


            
          </Col>
        </Row>
      </Container>
    )
  }
}

const DiscussButton = styled.button`
  background: #e39f1e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  border: none !important;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: 0.5s;
  margin: auto;
  &:hover {
    box-shadow: none;
  }
`