import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

const AboutPage = () => (
  <Layout>
    <SEO title="Email Success" noindex />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0" />
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">Email Success</h1>
            <ShortLine />

            <p>
              <strong>Thanks for subscribing to Chicken Yard.</strong>
            </p>
            <p>
              We'll email you from time to time with new tips, advice and
              helpful information about raising chickens. You can unsubscribe at any time.
            </p>
            <p>
              If you haven't already, please connect with us on{" "}
              <a
                title="Facebook"
                href="https://www.facebook.com/mychickenyard"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>{" "}
              to stay updated.
            </p>
            <p>All the best,</p>
            <p>
              <strong>Chicken Yard.</strong>
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
